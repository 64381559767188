import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "link-to-web"
    }}>{`Link to Web`}</h2>
    <p>{`Please note that this API is presently not publicly available, as the current
implementation is proprietary or customer specific.`}</p>
    <p>{`If you would like to use this API, please don't hesitate to contact us `}<a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=API%20Documentation%20request%20QR%20Code%20Link%20to%20Web&body=Dear%20AnyMessage%20Team%2C%0AI%20have%20read%20your%20online%20API%20Documentation%20and%20am%20intested%20in%20the%20following%20services%3A%0A%20%0A%20QR%20Code%20Link%20to%20Web%20API.%0A%20%0AYou%20can%20reach%20us%20via%20the%20following%3A%0A%20Mail%3A%20%22the%20one%20used%20here%22%20%0A%20Alternative%20E-Mail%3A%20%0A%20Phone%3A%0A%20%0AThank%20you%20in%20advance%20for%20getting%20back%20to%20us%3A%0A%20%0ANAME"
      }}>{`“here”`}</a>{`.`}</p>
    <p>{`Thank you for your understanding - We look forward to talking to you!`}</p>
    <h2 {...{
      "id": "link-to-sms"
    }}>{`Link to SMS`}</h2>
    <p>{`Please note that this API is presently not publicly available, as the current
implementation is proprietary or customer specific.`}</p>
    <p>{`If you would like to use this API, please don't hesitate to contact us `}<a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=API%20Documentation%20request%20QR%20Code%20Link%20to%20SMS&body=Dear%20AnyMessage%20Team%2C%0AI%20have%20read%20your%20online%20API%20Documentation%20and%20am%20intested%20in%20the%20following%20services%3A%0A%20%0A%20QR%20Code%20Link%20to%20SMS%20API.%0A%20%0AYou%20can%20reach%20us%20via%20the%20following%3A%0A%20Mail%3A%20%22the%20one%20used%20here%22%20%0A%20Alternative%20E-Mail%3A%20%0A%20Phone%3A%0A%20%0AThank%20you%20in%20advance%20for%20getting%20back%20to%20us%3A%0A%20%0ANAME"
      }}>{`“here”`}</a>{`.`}</p>
    <p>{`Thank you for your understanding - We look forward to talking to you!`}</p>
    <h2 {...{
      "id": "link-to-call"
    }}>{`Link to Call`}</h2>
    <p>{`Please note that this API is presently not publicly available, as the current
implementation is proprietary or customer specific.`}</p>
    <p>{`If you would like to use this API, please don't hesitate to contact us `}<a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=API%20Documentation%20request%20QR%20Code%20Link%20to%20Call&body=Dear%20AnyMessage%20Team%2C%0AI%20have%20read%20your%20online%20API%20Documentation%20and%20am%20intested%20in%20the%20following%20services%3A%0A%20%0A%20QR%20Code%20Link%20to%20Call%20API.%0A%20%0AYou%20can%20reach%20us%20via%20the%20following%3A%0A%20Mail%3A%20%22the%20one%20used%20here%22%20%0A%20Alternative%20E-Mail%3A%20%0A%20Phone%3A%0A%20%0AThank%20you%20in%20advance%20for%20getting%20back%20to%20us%3A%0A%20%0ANAME"
      }}>{`“here”`}</a>{`.`}</p>
    <p>{`Thank you for your understanding - We look forward to talking to you!`}</p>
    <h2 {...{
      "id": "link-to-other"
    }}>{`Link to Other`}</h2>
    <p>{`Please note that this API is presently not publicly available, as the current
implementation is proprietary or customer specific.`}</p>
    <p>{`If you would like to use this API, please don't hesitate to contact us `}<a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=API%20Documentation%20request%20QR%20Code%20Link%20to%20Other&body=Dear%20AnyMessage%20Team%2C%0AI%20have%20read%20your%20online%20API%20Documentation%20and%20am%20intested%20in%20the%20following%20services%3A%0A%20%0A%20QR%20Code%20Link%20to%20Other%20API.%0A%20%0AYou%20can%20reach%20us%20via%20the%20following%3A%0A%20Mail%3A%20%22the%20one%20used%20here%22%20%0A%20Alternative%20E-Mail%3A%20%0A%20Phone%3A%0A%20%0AThank%20you%20in%20advance%20for%20getting%20back%20to%20us%3A%0A%20%0ANAME"
      }}>{`“here”`}</a>{`.`}</p>
    <p>{`Thank you for your understanding - We look forward to talking to you!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      